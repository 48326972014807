/*============================
=            Blog            =
============================*/

.sticky {
	order:1;
}

.format-standard {
	word-wrap:break-word;
}


/*===================================
=            ## Comments            =
===================================*/

#comments-title {
	padding: 0 0 2.6em;
}
.nopassword,
.nocomments {
	color: $text;
	font-size: 24px;
	margin: 26px 0;
}
.commentlist {
	list-style: none;
	margin: 0 auto;
	width: 100%;
	> li.comment {
		position: relative;
	}
	pingback {
		margin: 0 0 10px;
		padding: 10px;
	}
	.children {
		list-style: none;
		margin: 0 0 10px 20px;
		.children {
			margin: 10px 0 10px 10px;
		}
		li.comment {
			position: relative;
		}
	}
}

article.comment {
	border-bottom: $border;
	padding: 10px 0;
}
.comment-meta {
	margin-bottom: 12px;
	.fn,
	time {
		font-style: normal;
		display: block;
		clear: right;
		color: $text;
	}
}
.avatar {
	float: left;
	padding-right: 10px;
	vertical-align: bottom;
}

.comment-content {
	p {
		margin-bottom: 1em;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

/* Post author highlighting */
.commentlist > li.bypostauthor, 
.commentlist .children > li.bypostauthor {
	border: $border;
	padding: 5px 10px;
}

/*=====  End of Comments  ======*/

/*=====  End of Blog  ======*/