/*================================
=            ## Footer          =
================================*/

footer {
	background: $darkGrey;
	height: 40px;
	p {
		margin: 0;
		color: darken($almostBlack, 5%);
		line-height: 40px;
		@include bp(medium) {
			padding-left: 2rem;
		}
		@include bp(mini) {
			@include fontSize(11);
		}
	}
}

/*=====  End of Footer  ======*/