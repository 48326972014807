/*=========================================
=            # WordPress Styles          =
=========================================*/

/* Images & Alignment */

.aligncenter {
  display: block;
  margin: 0 auto;
}

.textcenter {
  text-align: center;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

img {
  &.alignleft {
    margin-right: 1em;
    margin-bottom: 1.8em;
  }
  &.alignright {
    margin-left: 1em;
    margin-bottom: 1.8em;
  }
}

.text-right {
  text-align: right;
}

/* Content Styles */

.hentry {
  position: relative;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  .page & {
    border-bottom: none;
  }
}

.entry-content {
  table {
    border-bottom: $border;
    margin: 0 0 1.5em;
    width: 100%;
    td {
      border-top: $border;
      padding: 6px 10px 6px 0;
    }
  }
}
.comment-content {
  table {
    border-bottom: $border;
    margin: 0 0 1.5em;
    width: 100%;
    td {
      border-top: $border;
      padding: 6px 10px 6px 0;
    }
  }
}

dl.gallery-item {
  margin: 0;
}


/* Images & Embeds */
embed
iframe,
object {
  max-width: 100%;
}
img {
  max-width: 100%;
  &[class*="align"],
  &[class*="wp-image-"],
  &[class*="attachment-"] {
    height: auto;
  }
  &.size-full,
  &.size-large {
    width: auto; /* Prevent stretching of full-size and large-size images with height and width attributes in IE8 */
    height: auto; /* Make sure images with WordPress-added height and width attributes are scaled correctly */
  }
  &.alignleft,
  &.alignright,
  &.aligncenter {
    margin-bottom: 1.5em;
  }
}

/* Captions */
.wp-caption {
  max-width: 100%;
  height: auto;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
  .wp-caption-text {
    color: $text;
    font-size: 85%;
    margin-bottom: 0.5em;
    padding: 5px 0;
    position: relative;
  }
}

/* Galleries */
.gallery-caption {
  color: LINKCOLOR;
  font-size: 10px;
}
#content .gallery {
  margin: 0 auto 1.5em;
  a img {
    border: none;
  }
}
#content .gallery-columns-4 .gallery-item {
  width: 23%;
  padding-right: 2%;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/*=====  End of WordPress Styles  ======*/