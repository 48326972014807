// Sizes
// -------------------- 

$mainContent: 1200px;
$width: 95%;
// $primary-width: 75%;
// $secondary-width: 25%;


// Website Colors
// --------------------

$almostBlack: #2B2B2B;
$mainColor: #981B1F;
$grey: #D8D8D8;
$darkGrey: #979797;
$almostWhite: #FFFFFE;


// Color Usage
// ------------

$backgroundcolor: $grey;
$text: $almostBlack;
$linkcolor: $almostBlack;
$hovercolor: $mainColor;


// Fonts
// -----
$bodyFont: 'Open Sans', sans-serif;
$headingFont: $bodyFont;


// Borders
// -------
$border: 1px solid black;
