/*================================
=            ## Header           =
================================*/

header {
	background: $almostWhite;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
	z-index: 20;
	position: relative; // so Z-index will work - need z-index so border appears above the 'main' background color
	nav {
		@extend .flexParent;
		.navigation_logo {
			width: 30%;
			@include bp(little) {
				width: 70%;
			}
		}
		.siteMenu {
			list-style-type: none;
			width: 70%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			li {
 				a {
					display: inline-block;
					padding: 1rem 0 0.5rem 0;
					@include fontSize(15);
					font-weight: 700;
					transition: ease 0.5s;
				}
			}
			@include bp(little) {
				flex-direction: column;
				width: 100%;
				text-align: left;
				display: none;
				li a {
					margin: 0.5em 2em;
				}
			}
		}
		.hamburger {
			display: none;
			@include bp(little) {
				display: inline-block;
				width: 30%;
				text-align: right;
				padding: 1em;
				cursor: pointer;
			}
		}
		@include bp(little) {
			align-items: center;
		}
	}
	p {
		color: $mainColor;
		line-height: 0;
		text-align: right;
		padding-top: 2rem;
		margin: 0;
		@include bp(medium) {
			padding-right: 2rem;
		}
		@include bp(tiny) {
			display: none;
		}
	}
}

/* Underline Reveal */
// Hover.css - credit: http://ianlunn.github.io/Hover/
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: $mainColor;
  height: 4px;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/*=====  End of Header  ======*/
