/*====================================
=            ## Headings           =
====================================*/

h1, h2, h3, h4, h5, h6 {
	@include headingFont; 
	margin: 0.5em 0;
}

h1 {
	@include fontSize(50);
	@include lineHeight(60);
}

h2 {
	@include fontSize(32);
	@include lineHeight(42);
}

h3{
	@include fontSize(20);
	@include lineHeight(30);
}

h4 {
	@include fontSize(18);
	@include lineHeight(28);
}
h5 {
	@include fontSize(16);
	@include lineHeight(26);
}

h6 {
	@include fontSize(16);
	@include lineHeight(26);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	& a {
		color:$linkcolor;
	}
}

/*=====  End of Headings ======*/