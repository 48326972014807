/*================================
=            # Layout            =
================================*/

body {
	background: $backgroundcolor;
	margin: 0;
	padding: 0;
}
 
.container { 
	max-width: $mainContent;
	// width: $width;
	margin: 0 auto;
} 

main .container {
	background: $almostWhite;
}

img {
	max-width:100%;
	height:auto;
}

.flexParent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.underline {
	text-decoration: underline;
}

.floatLeft {
	float: left;
}

.floatRight {
	float: right;
}

/*=====  End of Layout  ======*/