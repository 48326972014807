/*====================================
=            # Typography           =
====================================*/

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
}

body, input, textarea {
	@include bodyFont;
	color: $text;
}

p {
	margin-bottom: 1.5em;
	line-height: 1.7;
	@include fontSize(16);
}

a {
	color: $linkcolor;
	text-decoration: none;
	&:focus,
	&:active,
	&:hover {
		color: $hovercolor;
	}
}

blockquote {
	font-style: italic;
	font-weight: normal;
}

@import "headings";
@import "lists";

/*=====  End of Typography  ======*/